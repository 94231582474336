import { useState, FC, useRef, useEffect, useCallback, memo, ReactNode } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import clsx from 'clsx'

import NavbarBottom from './navbar-bottom/navbar-bottom'
import NavbarRightSide from './navbar-right-side/navbar-right-side'
import SearchButton from './buttons/search-button/search-button'
import NavbarList from './navbar-list/navbar-list'
import Phone from 'components/parts/phone/phone'
import Logo from 'components/parts/logo/logo'
const SearchField = dynamic(() => import('./search-field/search-field'))
import usePCWindow from 'hooks/usePCWindow'
import useNavbarHeight from 'store/navbar/useNavbarHeight'

import { enableDocumentScroll } from 'utils/handlerDocumentScroll'
import { NavbarState } from './data'
import { ScreenSize } from 'constants/media-constanst'
import { IInfromData } from 'constants/types/navbar.types'

import style from './navbar.module.scss'

export interface INavbar {
  noLines?: boolean
  informData?: IInfromData
  isNoAuth?: boolean
  isInformLineHide: boolean
  children?: ReactNode
  noNavbarItems?: boolean
  phoneItem?: boolean
  onlyLogo?: boolean
}

const Navbar: FC<INavbar> = ({
  children,
  noLines = false,
  isInformLineHide,
  informData,
  isNoAuth,
  noNavbarItems,
  phoneItem = true,
  onlyLogo = false,
}: INavbar) => {
  const { pathname } = useRouter()
  const navRef = useRef<HTMLDivElement>(null)
  const isMobile = usePCWindow(ScreenSize.mobile)

  const { setNavbarHeight } = useNavbarHeight()

  const initMenu = NavbarState.NOT_OPENED
  const [openedMenu, setOpenedMenu] = useState(initMenu)

  const closeMenu = () => {
    setOpenedMenu(NavbarState.NOT_OPENED)
  }

  const handleMenuState = useCallback((newState: string) => {
    setOpenedMenu((prev) => {
      if (newState === prev) return NavbarState.NOT_OPENED
      else return newState
    })
  }, [])

  const toggleHamburgerState = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    handleMenuState(NavbarState.HAMBURGER)
  }
  const toggleSearchInput = () => handleMenuState(NavbarState.SEARCH)

  const isSearchOpen = openedMenu === NavbarState.SEARCH
  const isHamburgerOpen = openedMenu === NavbarState.HAMBURGER

  useEffect(() => enableDocumentScroll(), [])

  useEffect(() => {
    const height = navRef?.current?.offsetHeight

    if (height) setNavbarHeight(height)
  }, [isInformLineHide, informData?.lineClosed])

  if (onlyLogo) {
    return (
      <>
        <header ref={navRef} className={style.header}>
          <nav className={style.nav}>
            <div className={style.headerContent}>
              <div className={style.leftSide}>
                <Logo isResponsive={noNavbarItems} />
              </div>
            </div>
          </nav>
        </header>
      </>
    )
  }

  return (
    <>
      <header ref={navRef} className={style.header}>
        <nav className={style.nav}>
          <div className={style.headerContent}>
            <div className={style.leftSide}>
              {!noNavbarItems && (
                <button
                  className={style.hamburger}
                  type="button"
                  aria-label="Меню"
                  onClick={toggleHamburgerState}
                >
                  <span className={style.hamburgerBox}>
                    <span
                      className={clsx(style.hamburgerInner, {
                        [style.hamburgerInnerActive]: isHamburgerOpen,
                      })}
                    />
                  </span>
                </button>
              )}
              <Logo isResponsive={noNavbarItems} />
            </div>

            {!noNavbarItems && (
              <NavbarList
                isHamburgerOpen={isHamburgerOpen}
                pathname={pathname}
                closeMenu={closeMenu}
              />
            )}
            {noNavbarItems && phoneItem && <Phone />}
            <div className={style.rightSide}>
              <div onClick={toggleSearchInput} className={style.searchIcon}>
                <SearchButton isSearchInputShown={isSearchOpen} />
              </div>
              <NavbarRightSide
                openedMenu={openedMenu}
                handleMenuState={handleMenuState}
                isNoAuth={isNoAuth}
              />
            </div>
          </div>
          {isSearchOpen && !isMobile && <SearchField closeMenu={closeMenu} />}
        </nav>
        {children}
        <NavbarBottom isSearchOpen={isSearchOpen} noLines={noLines} navRef={navRef} />
      </header>
    </>
  )
}

export default memo(Navbar)
